/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { SupersetClient, logging, ClientConfig } from '@superset-ui/core';
import parseCookie from 'src/utils/parseCookie';

function getDefaultConfiguration(): ClientConfig {
	const csrfNode = document.querySelector<HTMLInputElement>('#csrf_token');
	const csrfToken = csrfNode?.value;

	// when using flask-jwt-extended csrf is set in cookies
	const cookieCSRFToken = parseCookie().csrf_access_token || '';

	return {
		protocol: ['http:', 'https:'].includes(window?.location?.protocol)
			? (window?.location?.protocol as 'http:' | 'https:')
			: undefined,
		host: (window.location && window.location.host) || '',
		csrfToken: csrfToken || cookieCSRFToken,
	};
}

export default function setupClient(customConfig: Partial<ClientConfig> = {}) {
	SupersetClient.configure({
		...getDefaultConfiguration(),
		...customConfig,
	})
		.init()
		.catch(error => {
			logging.warn('Error initializing SupersetClient', error);
		});
}
