/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import CategoricalScheme from '../../CategoricalScheme';

const schemes = [
	{
		id: 'lyftColors',
		label: 'Lyft Colors',
		colors: [
			'#EA0B8C',
			'#6C838E',
			'#29ABE2',
			'#33D9C1',
			'#9DACB9',
			'#7560AA',
			'#2D5584',
			'#831C4A',
			'#333D47',
			'#AC2077',
		],
	},
].map(s => new CategoricalScheme(s));

export default schemes;
